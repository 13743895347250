<template>
    <RegistryForm />
</template>

<script>
import RegistryForm from '@/components/Registries/RegistryForm'

export default {
    name: 'CreateRegistry',
    components: {
        RegistryForm
    },
    data: () => ({
        breadcrumbs: [
            { title: 'Главная', link: '/' },
            { title: 'Заказ ПО', link: '/' },
            { title: 'Перечень реестров платежей по договорам подряда', link: '/registries' },
            { title: 'Создание реестра платежей' }
        ]
    })
}
</script>
